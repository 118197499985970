import { AxiosResponse } from 'axios';
import { useCallback, useContext } from 'react';
// context
import { NotificationContext } from '../components/Notification/NotificationContext';
// interface
import {
  BookingDataResponse,
  Companion,
} from '../../components/BookingWidget/BookingManagment/bookingManagment.interface';
import {
  BookingQuoteResponse,
  BookingQuoteWithMedicalResponse,
} from '../../components/BookingWidget/BookingQuoteManagment/bookingQuoteResponse.interface';
import {
  BookingConfig,
  BookingConfigEnum,
  BookingStepValueEnum,
  BookingSubStepOption,
} from '../../components/BookingWidget/bookingSteps.interface';
import {
  MedicalCondition,
  MedicalScreeningResponse,
} from '../../components/BookingWidget/BookingQuoteManagment/medicalScreening.interface';
import { GetQuoteResponse } from '../interfaces/getQuote.interface';
import { BookingStepsContextState } from '../../context/bookingContext/bookingContext.types';
// util
import useApi from './api.service';
import parseDataToQuote from '../../components/BookingWidget/BookingQuoteManagment/bookingQuoteManagment.mapper';
import getBookingStepIndex from '../helpers/getBookingStepIndex.helper';
import { getUrlPlatformAndCatalog } from '../helpers/url.helper';
import { useSessionContext } from '../../context/sessionContext/sessionContext';

type UseQuoteDataServiceProps = {
  bookingConfigData: BookingConfig;
  bookingQuoteResponse: BookingQuoteResponse | null;
};

type CreateQuoteParams = {
  bookingSteps: BookingStepsContextState;
  bookingReferenceId: string | null;
  agentEmail?: string;
  bookingDataResponse: BookingDataResponse | null;
  bookingQuoteResponse: BookingQuoteResponse | null;
};

type GetQuoteParams = {
  id: string;
  quoteToken: string;
  bookingResponse: BookingDataResponse;
};

type UseQuoteDataService = {
  createQuote: (params: CreateQuoteParams) => Promise<BookingQuoteResponse>;
  getQuote: (
    params: GetQuoteParams,
  ) => Promise<BookingQuoteWithMedicalResponse>;
};

const useQuoteDataService = ({
  bookingConfigData,
  bookingQuoteResponse,
}: UseQuoteDataServiceProps): UseQuoteDataService => {
  // context
  const { showNotification } = useContext(NotificationContext);
  // util
  const { sessionParams } = useSessionContext();
  const API = useApi(bookingConfigData, sessionParams);
  // data
  const apiCM360Url: string =
    bookingConfigData?.[BookingConfigEnum.DataCenter]?.cm360Endpoint;
  const { decodedSessionToken } = useSessionContext();
  const createQuote = useCallback(
    async ({
      bookingSteps,
      agentEmail,
      bookingDataResponse,
      bookingReferenceId,
    }: CreateQuoteParams): Promise<BookingQuoteResponse> => {
      const { psClient } = bookingConfigData[BookingConfigEnum.Channel];
      const url = `${apiCM360Url}/ui-proxy/ws-partners/api/${getUrlPlatformAndCatalog(
        bookingConfigData,
      )}/quotes`;
      let payload = null;
      try {
        payload = parseDataToQuote(
          bookingSteps,
          bookingConfigData,
          {
            agentEmail,
          },
          decodedSessionToken,
          bookingDataResponse,
          bookingQuoteResponse,
          bookingReferenceId,
        );
      } catch (error) {
        console.log('error', error);
      }
      const response: AxiosResponse = await API.post(
        url,
        {
          ...payload,
          catalogVersion: bookingDataResponse?.bookingData.catalogVersion,
        },
        {
          headers: {
            'Client-Id': psClient,
          },
        },
      );
      return response.data;
    },
    [API, apiCM360Url, bookingConfigData],
  );

  const getQuote = useCallback(
    async ({
      id,
      quoteToken,
      bookingResponse,
    }: GetQuoteParams): Promise<BookingQuoteWithMedicalResponse> => {
      const quoteUrl = `${apiCM360Url}/ui-proxy/ws-partners/api/${getUrlPlatformAndCatalog(
        bookingConfigData,
      )}/quotes/${id}`;
      const { psClient } = bookingConfigData[BookingConfigEnum.Channel];

      let requestOptions;
      if (quoteToken) {
        requestOptions = {
          headers: {
            Authorization: `Bearer ${quoteToken}`,
            'Client-Id': psClient,
          },
        };
      } else {
        requestOptions = {
          headers: {
            'Client-Id': psClient,
          },
        };
      }
      const quoteResponse: AxiosResponse<GetQuoteResponse> = await API.get(
        quoteUrl,
        requestOptions,
      );

      if (quoteResponse.status !== 200) {
        showNotification('unexpectedError', 'error', false);
      }
      const quoteResult = quoteResponse.data;
      const { companions } = bookingResponse;
      const medicalConditionsRequests: Promise<MedicalCondition | null>[] = [];
      const QuotationInformationStepIndex = getBookingStepIndex(
        bookingConfigData,
        BookingStepValueEnum.QuotationInformation,
      );
      const presetConditions = bookingConfigData[
        BookingConfigEnum.BookingSteps
      ][QuotationInformationStepIndex].cards[0]
        .options as BookingSubStepOption[];
      const defaultAnswers = presetConditions
        .filter(
          (o: BookingSubStepOption) =>
            o?.label.includes('medicalCondition') ||
            o.value.includes('medicalCondition') ||
            o.label.includes('extraQuestion') ||
            o.value.includes('extraQuestion'),
        )
        .map((o: BookingSubStepOption, index: number) => ({
          orderId: index + 1,
          level: index < 3 ? 1 : 2,
          question: o.description ?? '',
          answer: 'No',
        }));

      if (companions?.some((c: Companion) => !!c.token)) {
        companions.forEach((c: Companion, i: number) => {
          const medicalToken = c.token;
          if (!medicalToken) {
            medicalConditionsRequests[i] = Promise.resolve(null);
          } else {
            const medicalConditionsUrl = `${apiCM360Url}/medical-screenings/${medicalToken}?filter=declarations,conditions`;
            medicalConditionsRequests[i] = API.get(medicalConditionsUrl).then(
              (res: AxiosResponse<MedicalScreeningResponse>) => {
                if (res.status !== 200) {
                  showNotification('unexpectedError', 'error', false);
                }
                const result = { ...res.data };
                if (!result.declarations) {
                  result.declarations = defaultAnswers;
                }
                return result;
              },
            );
          }
        });
        const medicalConditionsResult = await Promise.all(
          medicalConditionsRequests,
        );
        return {
          quoteResult,
          medicalConditionsResult,
        };
      }
      return {
        quoteResult,
        medicalConditionsResult: (companions ?? []).map(() => null),
      };
    },
    [API, apiCM360Url, bookingConfigData, showNotification],
  );

  return {
    createQuote,
    getQuote,
  };
};

export default useQuoteDataService;
